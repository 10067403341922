<template>
  <v-card tile :loading="loading">
    <v-system-bar color="error" window
      >Person <v-spacer></v-spacer
      ><v-btn small icon @click="close"
        ><v-icon small>mdi-close</v-icon></v-btn
      ></v-system-bar
    >
    <v-card-text class="pa-0 ma-0">
      <v-card-text class="title pt-8 pb-0 text-center">
        <PersonName :value="value" />
      </v-card-text>
      <v-card-text class="subtitle pt-0 text-center">
        {{ personCode(value) }}
      </v-card-text>

      <v-card-text class="display text-center">
        <v-avatar :size="$vuetify.breakpoint.xs ? 50 : 120">
          <PortraitImage :large="!$vuetify.breakpoint.xs" :value="value" />
        </v-avatar>
        <br />
      </v-card-text>

      <template v-if="flags && value.flags.length > 0">
        <v-divider />
        <v-container class="d-flex justify-center">
          <v-chip-group column>
            <v-chip
              v-for="flag in value.flags"
              :key="flag.description"
              outlined
            >
              <v-icon small left>{{ personFlagIcon(flag.code) }}</v-icon>
              {{ flag.description }}
            </v-chip>
          </v-chip-group>
        </v-container>
      </template>

      <template v-if="showChips">
        <v-divider />
        <v-container class="d-flex justify-center disabled">
          <v-chip-group column>
            <v-tooltip top v-for="chip in chips" :key="chip.description">
              <template v-slot:activator="{ on, attrs }">
                <v-chip outlined v-bind="attrs" v-on="on">
                  {{ chip.text }}:
                  {{
                    student.info[chip.value]
                      ? student.info[chip.value].code
                      : "–"
                  }}
                </v-chip>
              </template>
              <span
                >{{ chip.description }}:
                {{
                  student.info[chip.value]
                    ? student.info[chip.value].description
                    : "–"
                }}</span
              >
            </v-tooltip>
          </v-chip-group>
        </v-container>
      </template>
      <v-divider />
      <v-list subheader>
        <slot name="listitems" :close="close"></slot>
        <v-subheader>Links</v-subheader>
        <v-list-item
          @click="close"
          :to="{
            name: 'PersonContact',
            params: {
              id: value.id,
            },
          }"
        >
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profil anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="close"
          v-if="!$_hasRole('student')"
          :to="{ name: 'Register', params: { id: value.id } }"
        >
          <v-list-item-icon
            ><v-icon>mdi-account-details</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title> Unterricht anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="close"
          :to="{ name: 'Timetable', params: { code: personCode(value) } }"
        >
          <v-list-item-icon
            ><v-icon>mdi-calendar-clock</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title> Stundenplan anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="close"
          v-if="value.schoolClass && !$_hasRole('student')"
          :to="{ name: 'SchoolClass', params: { id: value.schoolClass.id } }"
        >
          <v-list-item-icon
            ><v-icon>mdi-account-multiple</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title> Klasse anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="showAbsences"
          @click="close"
          :to="{ name: 'AbsencesOverviewStudent', params: { id: value.id } }"
        >
          <v-list-item-icon><v-icon>mdi-bed</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Absenzen anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader>Aktionen</v-subheader>
        <v-list-item
          :href="'tel:' + student.phoneHome"
          v-if="student && student.phoneHome"
        >
          <v-list-item-icon><v-icon>mdi-phone</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>anrufen (zu Hause)</v-list-item-subtitle>
            <v-list-item-title>{{ student.phoneHome }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :href="'tel:' + student.phoneMobile"
          v-if="student && student.phoneMobile"
        >
          <v-list-item-icon><v-icon>mdi-cellphone</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>anrufen (Handy)</v-list-item-subtitle>
            <v-list-item-title>{{ student.phoneMobile }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :href="mailto(student.emailSchool)"
          target="_blank"
          v-if="student && student.emailSchool"
        >
          <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>E-Mail schreiben</v-list-item-subtitle>
            <v-list-item-title>{{ student.emailSchool }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider />

    <v-card-actions>
      <v-btn @click="close" color="primary" text block>schliessen</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { personCode } from "common/utils/people";
import { mailto } from "common/utils/helper.js";
import { personFlagIcon } from "common/utils/icons.js";
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage.vue";
export default {
  components: {
    PersonName,
    PortraitImage,
  },
  props: {
    flags: { type: Boolean, default: false },
    hideExt: { type: Boolean, default: false },
    value: null,
  },
  data() {
    return {
      sheet: false,
      student: null,
      loading: false,
      chips: [
        {
          text: "BI",
          description: "zweisprachiger Unterricht",
          value: "bilingual",
        },
        {
          text: "KU",
          description: "Kunstfach",
          value: "artsSubject",
        },
        {
          text: "S3",
          description: "Dritte Sprache",
          value: "thirdLanguage",
        },
        {
          text: "SF",
          description: "Schwerpunktfach",
          value: "majorSubject",
        },
        {
          text: "SF+",
          description: "Schwerpunktfach plus",
          value: "majorSubjectPlus",
        },
        {
          text: "EF",
          description: "Ergänzungsfach",
          value: "complementarySubject",
        },
      ],
    };
  },
  watch: {
    async value() {
      this.fetchData();
    },
  },
  computed: {
    showChips() {
      return (
        this.student &&
        this.student.info &&
        this.chips.some((el) => this.student.info[el.value])
      );
    },
    showAbsences() {
      if (!this.value.schoolClass) return false;
      return this.$_classTeacherOf
        .map((el) => el.code)
        .includes(this.value.schoolClass.code);
    },
  },
  methods: {
    personFlagIcon,
    mailto,
    personCode,
    close() {
      this.$emit("close");
    },
    async fetchData() {
      if (this.value) {
        this.loading = true;
        this.student = await this.apiGet({
          resource: "person/person",
          id: this.value.id,
        });
        this.loading = false;
      } else {
        this.student = null;
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
